/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-25",
    versionChannel: "nightly",
    buildDate: "2024-07-25T00:05:24.573Z",
    commitHash: "9065ce3a03bad8193d13542481bacc0caef170de",
};
